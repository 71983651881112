.textContainer {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    color: white;
    height: 60%;
    justify-content: space-between;
  }
  
  .activeContainer {
    display: flex;
    align-items: center;
    margin-bottom: 50%;
  }
  
  .activeItem {
    display: flex;
    align-items: center;
  }
  
  .activeContainer img {
    padding-left: 10px;
  }
  .onlineIcon {
    width: 15%;
  }
  .textContainer h1 {
    margin-bottom: 0px;
  }
  
  @media (min-width: 320px) and (max-width: 1200px) {
    .textContainer {
      display: none;
    }
  }