.infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #2979FF;
    border-radius: 4px 4px 0 0;
    height: 60px;
    width: 100%;
  }
  
  .leftInnerContainer {
    flex: 0.5;
    display: flex;
    align-items: center;
    margin-left: 5%;
    color: white;
  }
  
  .rightInnerContainer {
    display: flex;
    flex: 0.5;
    right: 1%;
    justify-content: flex-end;
    margin-right: 0rem;
    
  }
  
  .onlineIcon {
    margin-right: 5%;
    display: block;
    width: 5% ;
  }
  
  .closeIcon{
      display: block;
      width: 35% ;
      right: 10px;
  }
  .closeIcon:hover{
      opacity: 0.6;
  }